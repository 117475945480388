import { BackspaceIcon } from '@heroicons/react/outline'
import { KeyValue } from '../../helpers/keyboard'
import { getStatuses, CharStatus } from '../../helpers/statuses'
import { Key } from './Key'
import { useEffect } from 'react'

type Props = {
  onChar: (value: string) => void
  onDelete: () => void
  onEnter: () => void
  guesses: string[]
  isArabicKeyboard?: boolean
  solution: string
}

type KeyboardArabicProps = {
  onClick: (value: KeyValue) => void,
  charStatuses: { [key: string]: CharStatus }
}

function isArabic(text: string) {
  const arabic = /[\u0600-\u06FF]/
  const result = arabic.test(text)
  return result
}

const KeyboardArabic = ({ onClick, charStatuses }: KeyboardArabicProps) => {
  return (
    <div>
      <div className="flex justify-center mb-1">
        <Key value="ض" onClick={onClick} status={charStatuses['ض']} />
        <Key value="ص" onClick={onClick} status={charStatuses['ص']} />
        <Key value="ث" onClick={onClick} status={charStatuses['ث']} />
        <Key value="ق" onClick={onClick} status={charStatuses['ق']} />
        <Key value="ف" onClick={onClick} status={charStatuses['ف']} />
        <Key value="غ" onClick={onClick} status={charStatuses['غ']} />
        <Key value="ع" onClick={onClick} status={charStatuses['ع']} />
        <Key value="ه" onClick={onClick} status={charStatuses['ه']} />
        <Key value="خ" onClick={onClick} status={charStatuses['خ']} />
        <Key value="ح" onClick={onClick} status={charStatuses['ح']} />
        <Key value="ج" onClick={onClick} status={charStatuses['ج']} />
        <Key value="د" onClick={onClick} status={charStatuses['د']} />
      </div>
      <div className="flex justify-center mb-1">
        <Key value="ش" onClick={onClick} status={charStatuses['ش']} />
        <Key value="س" onClick={onClick} status={charStatuses['س']} />
        <Key value="ي" onClick={onClick} status={charStatuses['ي']} />
        <Key value="ب" onClick={onClick} status={charStatuses['ب']} />
        <Key value="ل" onClick={onClick} status={charStatuses['ل']} />
        <Key value="ا" onClick={onClick} status={charStatuses['ا']} />
        <Key value="ت" onClick={onClick} status={charStatuses['ت']} />
        <Key value="ن" onClick={onClick} status={charStatuses['ن']} />
        <Key value="م" onClick={onClick} status={charStatuses['م']} />
        <Key value="ك" onClick={onClick} status={charStatuses['ك']} />
        <Key value="ط" onClick={onClick} status={charStatuses['ط']} />
        <Key value="ذ" onClick={onClick} status={charStatuses['ذ']} />
      </div>
      <div className="flex justify-center">
        <Key value="ئ" onClick={onClick} status={charStatuses['ئ']} />
        <Key value="ء" onClick={onClick} status={charStatuses['ء']} />
        <Key value="ؤ" onClick={onClick} status={charStatuses['ؤ']} />
        <Key value="ر" onClick={onClick} status={charStatuses['ر']} />
        <Key value="ى" onClick={onClick} status={charStatuses['ى']} />
        <Key value="ة" onClick={onClick} status={charStatuses['ة']} />
        <Key value="و" onClick={onClick} status={charStatuses['و']} />
        <Key value="ز" onClick={onClick} status={charStatuses['ز']} />
        <Key value="ظ" onClick={onClick} status={charStatuses['ظ']} />
        <Key width={65.4} value="DELETE" onClick={onClick} bgColor={'red'} >
          <BackspaceIcon className="h-6 w-6 cursor-pointer" />
        </Key>
      </div>
      <div className="btnEnter flex justify-center">
        <Key width={90} height={45} value="ENTER" onClick={onClick} bgColor={'rgb(0, 156, 255)'}>
          Enter
        </Key>
      </div>
    </div>
  )
}

export const Keyboard = ({ onChar, onDelete, onEnter, guesses, isArabicKeyboard = false, solution }: Props) => {
  const charStatuses = getStatuses(guesses, solution)

  const onClick = (value: KeyValue) => {
    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    } else {
      if (isArabicKeyboard) {
        if (isArabic(value)) {
          onChar(value)
        }
      } else {
        onChar(value)
      }
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        onEnter()
      } else if (e.code === 'Backspace') {
        onDelete()
      } else {
        if (isArabicKeyboard) {
          if (isArabic(e.key)) {
            onChar(e.key)
          }
        } else {
          const key = e.key.toUpperCase()
          if (key.length === 1 && key >= 'A' && key <= 'Z') {
            onChar(key)
          }
        }
      }
    }
    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [onEnter, onDelete, onChar])

  if (isArabicKeyboard) {
    return (
      <KeyboardArabic 
        onClick={onClick}
        charStatuses={charStatuses}
      />
    )
  }

  return (
    <div>
      <div className="flex justify-center mb-1">
        <Key value="Q" onClick={onClick} status={charStatuses['Q']} />
        <Key value="W" onClick={onClick} status={charStatuses['W']} />
        <Key value="E" onClick={onClick} status={charStatuses['E']} />
        <Key value="R" onClick={onClick} status={charStatuses['R']} />
        <Key value="T" onClick={onClick} status={charStatuses['T']} />
        <Key value="Y" onClick={onClick} status={charStatuses['Y']} />
        <Key value="U" onClick={onClick} status={charStatuses['U']} />
        <Key value="I" onClick={onClick} status={charStatuses['I']} />
        <Key value="O" onClick={onClick} status={charStatuses['O']} />
        <Key value="P" onClick={onClick} status={charStatuses['P']} />
      </div>
      <div className="flex justify-center mb-1">
        <Key value="A" onClick={onClick} status={charStatuses['A']} />
        <Key value="S" onClick={onClick} status={charStatuses['S']} />
        <Key value="D" onClick={onClick} status={charStatuses['D']} />
        <Key value="F" onClick={onClick} status={charStatuses['F']} />
        <Key value="G" onClick={onClick} status={charStatuses['G']} />
        <Key value="H" onClick={onClick} status={charStatuses['H']} />
        <Key value="J" onClick={onClick} status={charStatuses['J']} />
        <Key value="K" onClick={onClick} status={charStatuses['K']} />
        <Key value="L" onClick={onClick} status={charStatuses['L']} />
      </div>
      <div className="flex justify-center">
        <Key width={65.4} value="ENTER" onClick={onClick}>
          Enter
        </Key>
        <Key value="Z" onClick={onClick} status={charStatuses['Z']} />
        <Key value="X" onClick={onClick} status={charStatuses['X']} />
        <Key value="C" onClick={onClick} status={charStatuses['C']} />
        <Key value="V" onClick={onClick} status={charStatuses['V']} />
        <Key value="B" onClick={onClick} status={charStatuses['B']} />
        <Key value="N" onClick={onClick} status={charStatuses['N']} />
        <Key value="M" onClick={onClick} status={charStatuses['M']} />
        <Key width={65.4} value="DELETE" onClick={onClick}>
          Delete
        </Key>
      </div>
    </div>
  )
}
