import { getGuessStatuses } from '../../helpers/statuses'
import { Cell } from './Cell'

type Props = {
  guess: string
  isArabicKeyboard?: boolean
  solution: string
}

export const CompletedRow = ({ guess, isArabicKeyboard = false, solution }: Props) => {
  const statuses = getGuessStatuses(guess, solution)

  if (isArabicKeyboard) {
    return (
      <div className="flex justify-center mb-1">
        {guess.split('').map((letter, i) => (
          <Cell key={i} value={letter} status={statuses[i]} />
        )).reverse()}
      </div>
    )
  }

  return (
    <div className="flex justify-center mb-1">
      {guess.split('').map((letter, i) => (
        <Cell key={i} value={letter} status={statuses[i]} />
      ))}
    </div>
  )
}
