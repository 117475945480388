import { ReactNode } from 'react'
import classnames from 'classnames'
import { KeyValue } from '../../helpers/keyboard'
import { CharStatus } from '../../helpers/statuses'

type Props = {
  children?: ReactNode
  value: KeyValue
  width?: number
  height?: number
  status?: CharStatus
  onClick: (value: KeyValue) => void
  bgColor?: string
}

export const Key = ({
  children,
  status,
  width = 40,
  height = 58,
  value,
  onClick,
  bgColor,
}: Props) => {
  const classes = classnames(
    'flex items-center justify-center rounded mx-0.5 text-m font-bold cursor-pointer',
    {
      'bg-slate-200 hover:bg-slate-300 active:bg-slate-400': !status,
      'bg-slate-400 text-white': status === 'absent',
      'bg-green-500 hover:bg-green-600 active:bg-green-700 text-white':
        status === 'correct',
      'bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white':
        status === 'present',
    }
  )

  return (
    <div
      style={!!bgColor ? { width: `${width}px`, height: `${height}px`, backgroundColor: bgColor, color: 'white' } : { width: `${width}px`, height: `${height}px` }}
      className={classes}
      onClick={() => onClick(value)}
    >
      {children || value}
    </div>
  )
}
