import { Cell } from './Cell'

type Props = {
  guess: string
  isArabicKeyboard?: boolean
}

export const CurrentRow = ({ guess, isArabicKeyboard = false }: Props) => {
  const splitGuess = guess.split('')
  const emptyCells = Array.from(Array(5 - splitGuess.length))

  if (isArabicKeyboard) {
    return (
      <div className="flex justify-center mb-1">
        {emptyCells.map((_, i) => (
          <Cell key={i} />
        ))}
        {splitGuess.map((letter, i) => (
          <Cell key={i} value={letter} />
        )).reverse()}
      </div>
    )
  }

  return (
    <div className="flex justify-center mb-1">
      {splitGuess.map((letter, i) => (
        <Cell key={i} value={letter} />
      ))}
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
    </div>
  )
}
