import { getGuessStatuses } from '../../helpers/statuses'
import { MiniCell } from './MiniCell'

type Props = {
  guess: string
  solution: string
  isArabicKeyboard?: boolean
}

export const MiniCompletedRow = ({ guess, solution, isArabicKeyboard = false }: Props) => {
  const statuses = getGuessStatuses(guess, solution)

  if (isArabicKeyboard) {
    return (
      <div className="flex justify-center mb-1">
        {guess.split('').map((letter, i) => (
          <MiniCell key={i} status={statuses[i]} />
        )).reverse()}
      </div>
    )
  }

  return (
    <div className="flex justify-center mb-1">
      {guess.split('').map((letter, i) => (
        <MiniCell key={i} status={statuses[i]} />
      ))}
    </div>
  )
}
