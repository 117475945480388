export function detectIphone() {
  const toMatch = [
    /iPhone/i,
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

export function detectIpad() {
  const toMatch = [
    /iPad/i,
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}
export function detectIpod() {
  const toMatch = [
    /iPod/i,
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

export function detectAndroid() {
  const toMatch = [
    /Android/i,
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}