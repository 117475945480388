const gameStateKey = 'gameState'
const lastDateKey = 'lastDate'
const nextDayDateKey = 'newtDayDate'
const startDateKey = 'startDate'

type StoredGameState = {
  guesses: string[]
  solution: string
}

export const saveGameStateToLocalStorage = (gameState: StoredGameState) => {
  localStorage.setItem(gameStateKey, JSON.stringify(gameState))
}

export const loadGameStateFromLocalStorage = () => {
  const state = localStorage.getItem(gameStateKey)

  return state ? (JSON.parse(state) as StoredGameState) : null
}

export const clearGameStateToLocalStorage = () => {
  localStorage.removeItem(gameStateKey)
}

// Last date
export const saveLastDateToLocalStorage = (lastDate: number) => {
  localStorage.setItem(lastDateKey, JSON.stringify(lastDate))
}

export const clearLastDateToLocalStorage = () => {
  localStorage.removeItem(lastDateKey)
}

export const loadLastDateFromLocalStorage = () => {
  const lastDate = localStorage.getItem(lastDateKey)

  return lastDate ? (JSON.parse(lastDate) as number) : null
}

// Next day date
export const saveNextDayDateToLocalStorage = (nextDayDate: number) => {
  localStorage.setItem(nextDayDateKey, JSON.stringify(nextDayDate))
}

export const clearNextDayDateToLocalStorage = () => {
  localStorage.removeItem(nextDayDateKey)
}

export const loadNextDayDateFromLocalStorage = () => {
  const nextDayDate = localStorage.getItem(nextDayDateKey)

  return nextDayDate ? (JSON.parse(nextDayDate) as number) : null
}

// Start date
export const saveStartDateToLocalStorage = (startDate: Date) => {
  localStorage.setItem(startDateKey, JSON.stringify(startDate))
}

export const clearStartDateToLocalStorage = () => {
  localStorage.removeItem(startDateKey)
}

export const loadStartDateDateFromLocalStorage = () => {
  const startDate = localStorage.getItem(startDateKey)

  return startDate ? (JSON.parse(startDate) as Date) : null
}
