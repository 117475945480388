import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import { MiniGrid } from '../mini-grid/MiniGrid'
import { shareStatus, shareMobileStatus } from '../../helpers/share'
import { XCircleIcon } from '@heroicons/react/outline'

function detectMob() {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  handleShare: () => void
  isLost?: boolean
  timeRested: string | null
  solution: string
}

export const WinModal = ({
  isOpen,
  handleClose,
  guesses,
  handleShare,
  isLost = false,
  timeRested = null,
  solution,
}: Props) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div className="absolute right-4 top-4">
                <XCircleIcon
                  className="h-6 w-6 cursor-pointer"
                  onClick={() => handleClose()}
                />
              </div>
              <div>
                {!isLost && <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CheckIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>}
                {!!isLost && <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <XIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>}
                {!isLost && <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {
                      process.env.REACT_APP_LANG === 'Arabic' ? '! تخمين صحيح' : 'You won!'
                    }
                  </Dialog.Title>
                  <div className="mt-2">
                    <MiniGrid guesses={guesses} solution={solution} isArabicKeyboard={process.env.REACT_APP_LANG === 'Arabic'} />
                  </div>
                </div>}
                {!!isLost && <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {
                      process.env.REACT_APP_LANG === 'Arabic' ? '! تخمين خاطئ' : `You lost!`
                    }
                  </Dialog.Title>
                  <div className="mt-2">
                    <MiniGrid guesses={guesses} solution={solution} isArabicKeyboard={process.env.REACT_APP_LANG === 'Arabic'} />
                  </div>
                </div>}
                {!!timeRested && <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium"
                  >
                    {
                      process.env.REACT_APP_LANG === 'Arabic' ? `${solution + ' هي كلمة اليوم'}` : `The today's word is ${solution}`
                    }
                  </Dialog.Title>
                  <br/>
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {
                      process.env.REACT_APP_LANG === 'Arabic' ? 'الكلمة التالية' : 'NEXT WORDLE'
                    }
                  </Dialog.Title>
                  <div>
                    <p className="text-lg text-gray-500">{ timeRested }</p>
                  </div>
                </div>}
              </div>
              {
                !detectMob() &&
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    onClick={() => {
                      shareStatus(guesses, solution, isLost)
                      handleShare()
                    }}
                  >
                    {
                      process.env.REACT_APP_LANG === 'Arabic' ? 'شارك النتيجة' : 'Share'
                    }
                  </button>
                </div>
              }
              {
                !!detectMob() &&
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    onClick={() => {
                      shareMobileStatus(guesses, solution, isLost)
                      handleShare()
                    }}
                  >
                    {
                      process.env.REACT_APP_LANG === 'Arabic' ? 'شارك النتيجة' : 'Share'
                    }
                  </button>
                </div>
              }
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
