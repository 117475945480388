import { InformationCircleIcon /*, RefreshIcon */ } from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import OneSignal from 'react-onesignal'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { WinModal } from './components/modals/WinModal'
import { RefreshModal } from './components/modals/RefreshModal'
import { /* isWordInWordList, */ isWinningWord, solutionIndex } from './helpers/words'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  clearGameStateToLocalStorage,
  saveLastDateToLocalStorage,
  loadLastDateFromLocalStorage,
  clearLastDateToLocalStorage,
  saveNextDayDateToLocalStorage,
  clearNextDayDateToLocalStorage,
  loadNextDayDateFromLocalStorage,
  saveStartDateToLocalStorage,
  loadStartDateDateFromLocalStorage,
  clearStartDateToLocalStorage,
} from './helpers/localStorage'
import { detectAndroid, detectIphone } from './helpers/device'

const msToTime = (duration: number) => {
  const seconds = Math.floor((duration / 1000) % 60)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  const hoursFormatted = (hours < 10) ? "0" + hours : hours
  const minutesFormatted = (minutes < 10) ? "0" + minutes : minutes
  const secondsFormatted = (seconds < 10) ? "0" + seconds : seconds

  return hoursFormatted + ":" + minutesFormatted + ":" + secondsFormatted
}

const restFrom24hours = (duration: number) => {
  return msToTime(duration);
}

const daysBetween = (first: Date, second: Date) => {

  var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
  var two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

  var millisecondsPerDay = 1000 * 60 * 60 * 24;
  var millisBetween = two.getTime() - one.getTime();
  var days = millisBetween / millisecondsPerDay;

  return Math.floor(days);
}

const isMoreThanADay = () => {
  const now = Date.now();
  const nextDayDate = loadNextDayDateFromLocalStorage()
  return nextDayDate && (now - nextDayDate) > 0
}

const isMoreThanADayFromStart = () => {
  const now = new Date();
  const startDateFromLocalStorage = loadStartDateDateFromLocalStorage();
  if (!!startDateFromLocalStorage) {
    const startDate = new Date(startDateFromLocalStorage)
    return startDate && daysBetween(startDate, now) > 0
  }
  return false;
}

function App() {
  const [oneSignalInitialized, setOneSignalInitialized] = useState(false);
  // const [listOfWords, setListOfWords] = useState<string[]>([])
  // const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [solution, setSolution] = useState('')
  const [isPushNotifsEnabled, setIsPushNotifsEnabled] = useState<boolean | undefined>(undefined)
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isWinModalOpen, setIsWinModalOpen] = useState(false)
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [shareComplete, setShareComplete] = useState(false)
  const [nowTime, setNowTime] = useState(Date.now())
  const [guesses, setGuesses] = useState<string[]>([])

  const lastDate = loadLastDateFromLocalStorage()
  const nextDayDate = loadNextDayDateFromLocalStorage()

  useEffect(() => {
    OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APP_ID || '' }).then(() => {
      setOneSignalInitialized(true)
    })
    OneSignal.isPushNotificationsEnabled().then((isEnabled) => {
      if (isEnabled) {
        setIsPushNotifsEnabled(true)
      } else {
        setIsPushNotifsEnabled(false)
      }
    })
  }, [oneSignalInitialized]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const mode = searchParams ? searchParams?.get('mode') : null;
    console.log('mode', mode);
    if (!detectAndroid() && !detectIphone()) {
      fetch(`https://yawmiya.co/api?yaw=${solutionIndex}`)
        .then(res => res.json())
        .then(
          (result: { today: string, list: Array<string>}) => {
            /* if (result && result?.list) {
              setListOfWords(result.list);
            } */
            if (result?.today) {
              const loaded = loadGameStateFromLocalStorage()
              if (loaded?.solution !== result.today) {
                setGuesses([])
              }
              if (loaded?.guesses?.includes(result.today)) {
                setIsGameWon(true)
              } else if (loaded?.guesses?.length === Number(process.env.REACT_APP_LENGTH_OF_LINES)) {
                setIsGameLost(true)
              } else if (!loaded?.guesses?.length || loaded?.guesses?.length === 0) {
                saveStartDateToLocalStorage(new Date())
              }
              setSolution(result.today);
              if (!!loaded?.guesses?.length && loaded?.guesses?.length < Number(process.env.REACT_APP_LENGTH_OF_LINES) && isMoreThanADayFromStart()) {
                clearGameStateToLocalStorage();
                saveGameStateToLocalStorage({ guesses: [], solution: '' })
                clearLastDateToLocalStorage()
                clearNextDayDateToLocalStorage()
                saveStartDateToLocalStorage(new Date())
              } else {
                setGuesses(loaded?.guesses || [])
              }
            }
          },
          (error) => {
            // console.log('errorr', error)
          }
        )
    } else {
      if (!!detectIphone()) {
        if (mode === 'unlimited') {
          window.location.replace("https://apps.apple.com/us/app/yawmiya/id6444901582"); // yawmiya.unlimited
        } else {
          window.location.replace("https://apps.apple.com/us/app/yawmiya/id1632254791"); // yawmiya
        }
      }
      if (!!detectAndroid()) {
        if (mode === 'unlimited') {
          window.location.replace("https://play.google.com/store/apps/details?id=com.yawmiya.unlimited"); // yawmiya.unlimited
        } else {
          window.location.replace("https://play.google.com/store/apps/details?id=com.yawmiya"); // yawmiya
        }
      }
    }
  }, [])

  useEffect(() => {
    let interval: any = null
    if (lastDate && nextDayDate) {
      if (oneSignalInitialized) {
        setTimeout(() => {
          OneSignal.showSlidedownPrompt().then(() => {
            // do other stuff
          });
        }, 5000);
      }
      interval = setInterval(() => {
        if (isMoreThanADay()) {
          clearInterval(interval)
          clearGameStateToLocalStorage()
          saveGameStateToLocalStorage({ guesses: [], solution: '' })
          clearLastDateToLocalStorage()
          clearNextDayDateToLocalStorage()
        } else {
          setNowTime(Date.now())
        }
      }, 1000)
    } else {
      if (interval) {
        clearInterval(interval)
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isGameLost, isGameWon])

  useEffect(() => {
    if(solution) {
      saveGameStateToLocalStorage({ guesses, solution: solution || '' })
    }
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      setIsWinModalOpen(true)
    }
  }, [isGameWon])

  const onChar = (value: string) => {
    if (currentGuess.length < 5 && guesses.length < Number(process.env.REACT_APP_LENGTH_OF_LINES)) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    /*if (!isWordInWordList(currentGuess, listOfWords)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, 2000)
    }*/

    const winningWord = isWinningWord(currentGuess, solution)

    if (currentGuess.length === 5 && guesses.length < Number(process.env.REACT_APP_LENGTH_OF_LINES) && !isGameWon) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        saveLastDateToLocalStorage(Date.now())
        const nextDay = new Date();
        nextDay.setHours(24,0,0,0);
        saveNextDayDateToLocalStorage(nextDay.getTime())
        setNowTime(Date.now())
        clearStartDateToLocalStorage()
        return setIsGameWon(true)
      }

      if (guesses.length === (Number(process.env.REACT_APP_LENGTH_OF_LINES) - 1)) {
        saveLastDateToLocalStorage(Date.now())
        const nextDay = new Date();
        nextDay.setHours(24,0,0,0);
        saveNextDayDateToLocalStorage(nextDay.getTime())
        setNowTime(Date.now())
        clearStartDateToLocalStorage()
        return setIsGameLost(true)
      }
    }
  }

  const shareTheGame = () => {
    if (navigator?.share) {
      const text = 'https://yawmiya.co';
      navigator?.share({
        text,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error))
    }
  }

  const reminder = () => {
    if (oneSignalInitialized) {
      OneSignal.showSlidedownPrompt({ force: true }).then(() => {
        setIsPushNotifsEnabled(true)
      })
    }
  }

  const timeRested = nextDayDate ? restFrom24hours(nextDayDate - nowTime) : null;

  if (!solution) {
    return <div>
      loading...
    </div>
  }

  return (
    <>
      {
        process.env.REACT_APP_LANG === 'Arabic' &&
        <div 
          style={{ 
            position: 'fixed', top: 0, backgroundColor: 'black', display: "flex", 
            width: "100%", justifyContent: 'center', alignItems: 'center',
            padding: 15
          }}
        >
          {
            isPushNotifsEnabled === false && 
            <button
              type="button"
              style={{ marginBlock: 0, backgroundColor: "transparent", color: "white", borderWidth: 0, fontSize: 15 }}
              className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => reminder()}
            >
              تذكير
            </button>
          }
          {
            !!navigator?.share && 
            <button
              type="button"
              style={{ marginBlock: 0, backgroundColor: "transparent", color: "white", borderWidth: 0, fontSize: 15 }}
              className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => shareTheGame()}
            >
              شارك اللعبة
            </button>
          }
          <button
            type="button"
            style={{ marginBlock: 0, backgroundColor: "transparent", color: "white", borderWidth: 0, fontSize: 15 }}
            className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setIsAboutModalOpen(true)}
          >
            من نحن
          </button>
          <button
            type="button"
            style={{ marginBlock: 0, backgroundColor: "transparent", color: "white", borderWidth: 0, fontSize: 15 }}
            className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setIsInfoModalOpen(true)}
          >
            كيف تلعب
          </button>
        </div>
      }
      {
        process.env.REACT_APP_LANG !== 'Arabic' &&
        <div
          style={{ 
            position: 'fixed', top: 0, backgroundColor: 'black', display: "flex", 
            width: "100%", justifyContent: 'center', alignItems: 'center',
            padding: 15
          }}
        >
          <button
            type="button"
            style={{ marginBlock: 0, backgroundColor: "transparent", color: "white", borderWidth: 0, fontSize: 15 }}
            className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setIsInfoModalOpen(true)}
          >
            How to play
          </button>
          <button
            type="button"
            style={{ marginBlock: 0, backgroundColor: "transparent", color: "white", borderWidth: 0, fontSize: 15 }}
            className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setIsAboutModalOpen(true)}
          >
            About us
          </button>
          <button
            type="button"
            style={{ marginBlock: 0, backgroundColor: "transparent", color: "white", borderWidth: 0, fontSize: 15 }}
            className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => shareTheGame()}
          >
            Share the game
          </button>
        </div>
      }
      <div className="gameBlock py-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
        {/*
          process.env.REACT_APP_LANG !== 'Arabic' &&
          <Alert message="Word not found" isOpen={isWordNotFoundAlertOpen} />
        */ }
        {/*
          process.env.REACT_APP_LANG === 'Arabic' &&
          <Alert message="كلمة غير موجودة" isOpen={isWordNotFoundAlertOpen} />
        */ }
        <Alert
          message="Game copied to clipboard"
          isOpen={shareComplete}
          variant="success"
        />
        <div className="titleBlock flex w-80 mx-auto items-center mb-8">

          {
            /*
            process.env.REACT_APP_LANG === 'Arabic' && !isGameLost && !isGameWon && !!guesses.length &&
              <RefreshIcon
                className="h-6 w-6 cursor-pointer"
                onClick={() => {
                  setIsRefreshModalOpen(true)
                }}
              />
            */
          }
          {
            process.env.REACT_APP_LANG === 'Arabic' &&
            <>
              {/* <InformationCircleIcon
                className="h-6 w-6 cursor-pointer hide"
                onClick={() => {
                  setIsInfoModalOpen(true)
                }}
              /> */}
              <h1 dir="auto" className="text-xl grow font-bold center whiteColor">yawmiya.co يومية - كلمة كل يوم</h1>
            </>
          }
          {
            process.env.REACT_APP_LANG !== 'Arabic' &&
            <>
              <h1 className="text-xl grow font-bold">Not Wordle</h1>
              <InformationCircleIcon
                className="h-6 w-6 cursor-pointer"
                onClick={() => {
                  setIsInfoModalOpen(true)
                }}
              />
            </>
          }
          {
            /*
            process.env.REACT_APP_LANG !== 'Arabic' && !isGameLost && !isGameWon && !!guesses.length &&
              <RefreshIcon
                className="h-6 w-6 cursor-pointer"
                onClick={() => {
                  setIsRefreshModalOpen(true)
                }}
              />
            */
          }
        </div>
        <Grid 
          guesses={guesses} 
          currentGuess={currentGuess} 
          isArabicKeyboard={process.env.REACT_APP_LANG === 'Arabic'}
          solution={solution}
        />
        <Keyboard
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
          guesses={guesses}
          isArabicKeyboard={process.env.REACT_APP_LANG === 'Arabic'}
          solution={solution}
        />
        <WinModal
          isOpen={isWinModalOpen}
          solution={solution}
          handleClose={() => setIsWinModalOpen(false)}
          guesses={guesses}
          handleShare={() => {
            setIsWinModalOpen(false)
            setShareComplete(true)
            return setTimeout(() => {
              setShareComplete(false)
            }, 2000)
          }}
          timeRested={timeRested}
        />
        <WinModal
          isLost
          solution={solution}
          isOpen={isGameLost}
          handleClose={() => setIsGameLost(false)}
          guesses={guesses}
          handleShare={() => {
            setIsGameLost(false)
            setShareComplete(true)
            return setTimeout(() => {
              setShareComplete(false)
            }, 2000)
          }}
          timeRested={timeRested}
        />
        <RefreshModal
          isOpen={isRefreshModalOpen}
          handleClose={() => setIsRefreshModalOpen(false)}
          handleConfirm={() => {
            setIsRefreshModalOpen(false)
            clearGameStateToLocalStorage();
            saveGameStateToLocalStorage({ guesses: [], solution: solution || '' })
            setGuesses([])
          }}
        />
        <InfoModal
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
        />
        <AboutModal
          isOpen={isAboutModalOpen}
          handleClose={() => setIsAboutModalOpen(false)}
        />
      </div>
    </>
  )
}

export default App
