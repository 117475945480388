import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'

export const shareStatus = (guesses: string[], solution: string, isLost: boolean) => {
  if (process.env.REACT_APP_LANG === 'Arabic') {
    const guessessLength = !!isLost ? 'X' : guesses.length;
    navigator.clipboard.writeText(
        '#YawmiyaGame' +
        '\n' +
        'كلمة ' +
        solutionIndex +
        ' - ' +
        guessessLength +
        '/' + Number(process.env.REACT_APP_LENGTH_OF_LINES) + '\n\n' +
        generateEmojiGrid(guesses, solution)
        // '\n\n' +
        // 'جرب حظك من هنا' +
        // '\n' +
        // 'https://yawmiya.co'
    )
  } else {
    const guessessLength = !!isLost ? 'X' : guesses.length;
    navigator.clipboard.writeText(
      '#YawmiyaGame ' +
        '\n' +
        "Word " +
        solutionIndex +
        ' - ' +
        guessessLength +
        '/' + Number(process.env.REACT_APP_LENGTH_OF_LINES) + '\n\n' +
        generateEmojiGrid(guesses, solution)
        // '\n\n' +
        // 'https://yawmiya.co'
    )
  }
}

export const shareMobileStatus = (guesses: string[], solution: string, isLost: boolean) => {
  const guessessLength = !!isLost ? 'X' : guesses.length;
  const slashLengthOfTries = '/' + Number(process.env.REACT_APP_LENGTH_OF_LINES) + '\n\n';

   if (navigator?.share) {
    const text = '#يومية' + '_' + solutionIndex + '\n' + 'كلمة اليوم ' + ' - ' + guessessLength + slashLengthOfTries + generateEmojiGrid(guesses, solution)+ '\n\n' + 'جرب حظك من هنا' + '\n' + 'https://yawmiya.co';
    navigator?.share({
      text,
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error))
  } else {
    shareStatus(guesses, solution, isLost)
  }
}

export const generateEmojiGrid = (guesses: string[], solution: string) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess, solution)
      return guess
        .split('')
        .map((letter, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟢'
            case 'present':
              return '🔵'
            default:
              return '🔴'
          }
        })
        .join('')
    })
    .join('\n')
}
